// Preloader
//$(window).load(function() {
//    $("#status").fadeOut();
//    $("#preloader").delay().fadeOut("slow");
//    $("body").delay().queue(function(next) { $(this).addClass("loaded");
//        next(); })
//});

// Fixed Menu
if ($('header').hasClass('header-nav')) {
  $(window).scroll(function() {
      if ($(window).scrollTop() > 100) {
          $('.header-nav').addClass('sticky animated fadeInDown');
      } else {
          $('.header-nav').removeClass('sticky animated fadeInDown');
      }
  });
}

// Dropdown Menu
$('.navbar-nav .dropdown').hover(
  function () {
    $(this).find('.dropdown-menu').first().stop(true, true).slideDown(150)
  },
  function () {
    $(this).find('.dropdown-menu').first().stop(true, true).slideUp(150)
  }
)

// Navigation Active
// $(function () {
//   $('#navbar .navbar-nav').smartmenus({
//     subMenusSubOffsetX: 0,
//     subMenusSubOffsetY: 0,
//     markCurrentItem: true,
//     subIndicatorsPos: 'append',
//     showTimeout: 50,
//     hideTimeout: 50,
//   })
// })
$(window).load(function(){
  $('#main-menu').smartmenus({
    mainMenuSubOffsetX: -1,
    subMenusSubOffsetX: 10,
    subMenusSubOffsetY: 0
  });
});

// Shopping Cart Dropdown Menu
$(document).ready(function () {
  $('#cart .cart-dropdown').hover(
    function () {
      $('.mini-cart-window').stop(true, true).slideDown(200)
    },
    function () {
      $('.mini-cart-window').stop(true, true).slideUp(200)
    }
  )
  $('#cart .member-dropdown').hover(
    function () {
      $('.mini-member-window').stop(true, true).slideDown(200)
    },
    function () {
      $('.mini-member-window').stop(true, true).slideUp(200)
    }
  )
  $('#cart .lang-dropdown').hover(
    function () {
      $('.mini-lang-window').stop(true, true).slideDown(200)
    },
    function () {
      $('.mini-lang-window').stop(true, true).slideUp(200)
    }
  )
})

// Mobile Menu
$(document).ready(function () {
  $('nav#menu').mmenu({
    extensions: ['effect-slide', 'pageshadow'],
    header: true,
    counters: true,
    offCanvas: { position: 'left', zposition: 'front' },
    footer: {
      add: true,
      content: 'Copyright © 泰菱有限公司 All rights reserved.',
    },
  })
  $('#menu').trigger('mm-opened')
})

// Search
$('#m-search-icon').click(function () {
  $('#search-form').fadeIn(300)
  $('#search-form input').focus()
})
$('#search-form input').blur(function () {
  $('#search-form').fadeOut(300)
})

// Tooltips
$('[data-toggle="tooltip"]').tooltip({ placement: 'top' })

// Back to Top
$(document).ready(function () {
  $('#back-top').hide()
  $(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#back-top').fadeIn()
      } else {
        $('#back-top').fadeOut()
      }
    })
    $('#back-top').click(function () {
      $('body,html').animate({ scrollTop: 0 }, 800)
      return false
    })
  })
})
